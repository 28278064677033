// Import necessary modules
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './App.css';

const App = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneType, setPhoneType] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!name) {
      setError('Please enter your name.');
      return;
    }

    if (!phoneType) {
      setError('Please select your mobile phone type.');
      return;
    }

    if (!captchaVerified) {
      setError('Please verify the captcha.');
      return;
    }

    // If valid, simulate submission
    setError('');
    setSubmitted(true);

    // Send data to Google Sheets
    fetch(process.env.REACT_APP_GOOGLE_SHEET_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        name,
        phoneType,
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log('Form submitted successfully to Google Sheets');
        } else {
          console.error('Error submitting form to Google Sheets');
        }
      })
      .catch((err) => console.error('Fetch error:', err));

    console.log('Form submitted:', { email, name, phoneType });
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Routinity</h1>
        <p>Sign up to discover and create routines!</p>
        {!submitted ? (
          <form onSubmit={handleSubmit} className="signup-form">
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              required
            />
            <br/>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <br/>
            <br/>
            <label> <b> Mobile Phone Type: </b> </label>
            <select
              id="phoneType"
              value={phoneType}
              onChange={(e) => setPhoneType(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="Android">Android</option>
              <option value="iOS">iOS</option>
            </select>
            <br/>
            <br/>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleCaptchaChange}
            />
            <br/>
            {error && <p className="error">{error}</p>}
            <button type="submit">Sign Up</button>
          </form>
        ) : (
          <p>Thank you for signing up! Check your inbox for more details.</p>
        )}
      </header>
    </div>
  );
};

export default App;